var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-expansion-panels',{staticClass:"condensed my-1",attrs:{"multiple":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('p',[_vm._v(" Impuestos Opción - "+_vm._s(_vm.index)+" : "),_c('b',[_vm._v(_vm._s(_vm.calcularTotalImpuesto()))])])]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.impuestos,"item-key":"id","dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.porcentaje",fn:function(ref){
var item = ref.item;
return [(!_vm.noPorcentaje.includes(item.codigo))?_c('v-text-field',{attrs:{"dense":"","type":"number","step":"0.01","prefix":"%","disabled":""},model:{value:(item.codigo01),callback:function ($$v) {_vm.$set(item, "codigo01", $$v)},expression:"item.codigo01"}}):_vm._e()]}},{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcularImpuestos({ item: item }))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }